import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Home from './Home';
import AyanamiResearch from './AyanamiResearch';

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  in: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
  out: {
    opacity: 0,
    scale: 1.2,
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
};
  
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              style={{ width: '100%' }}
            >
              <Home />
            </motion.div>
          }
          />
        <Route
          path="/ayanami"
          element={
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              style={{ width: '100%' }}
            >
              <AyanamiResearch />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};
  
export default AnimatedRoutes;