import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faItchIo } from '@fortawesome/free-brands-svg-icons';
import GlitchEffect from './GlitchEffect';
import Loading from './Loading';
import '../css/Home.css';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [randomQuote, setRandomQuote] = useState('');
  const [applyGlitch, setApplyGlitch] = useState(false);
  const [glitchSpeed, setGlitchSpeed] = useState('1.5s');
  const [glitchIntensity, setGlitchIntensity] = useState('8px');

  useEffect(() => {
    const quotes = [
      "Some things you forget, some things you can never forget. It's funny: I'm not sure which one is sadder.",
      "He who is not bold enough to be stared at from across the abyss is not bold enough to stare into it himself.",
      "What you call faith is nothing more than a child crying out to be loved.",
      "You see it too. For me, it's always like this.",
      "Monsters? They look like monsters to you?",
      "You made me happy.",
      "I can't tell you to remember me, but I can't bear for you to forget me.",
      "In my restless dreams, I see that town. You promised me you'd take me there again someday. But because of me, you were never able to.",
      "You've been unfaithful. Is that true?",
    ];

    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);

    const handleLoad = () => setIsLoading(false);

    const allElements = document.querySelectorAll('img, iframe');
    let loadedElements = 0;

    allElements.forEach((element) => {
      if (element.complete) {
        loadedElements += 1;
      } else {
        element.addEventListener('load', () => {
          loadedElements += 1;
          if (loadedElements === allElements.length) {
            handleLoad();
          }
        });
      }
    });

    if (loadedElements === allElements.length) {
      handleLoad();
    }

    const shouldApplyGlitch = Math.random() < 0.15;
    setApplyGlitch(shouldApplyGlitch);

    setGlitchSpeed('3.5s');
    setGlitchIntensity('10px');
  }, []);

  const { ref: aboutRef, inView: aboutInView } = useInView({
    triggerOnce: false,
    threshold: 0.7,
  });

  const { ref: githubRef, inView: githubInView } = useInView({
    triggerOnce: false,
    threshold: 0.7,
  });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  if (isLoading) {
    return <Loading />;
  }

  const glitchImageClass = applyGlitch ? 'glitch-image' : '';
  const noiseClass = applyGlitch ? 'noise' : '';
  const colorFlashClass = applyGlitch ? 'color-flash' : '';

  return (
    <div className="container" style={{ position: 'relative', minHeight: '100vh' }}>
      <div className="overlay-container">
        <div className={`overlay ${glitchImageClass}`}></div>
      </div>

      <div className="fog"></div>
      {applyGlitch && <div className={noiseClass}></div>}

      <motion.div
        ref={aboutRef}
        initial="hidden"
        animate={aboutInView ? 'visible' : 'hidden'}
        variants={variants}
        className={colorFlashClass}
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <GlitchEffect
          text="Innocent Moon"
          className={`header ${applyGlitch ? 'glitch' : ''}`}
          glitchSpeed={glitchSpeed}
          glitchIntensity={glitchIntensity}
        />
        <GlitchEffect
          text={randomQuote}
          className={`quote ${applyGlitch ? 'glitch' : ''}`}
          glitchSpeed={glitchSpeed}
          glitchIntensity={glitchIntensity}
        />
      </motion.div>
      <motion.div
        ref={githubRef}
        initial="hidden"
        animate={githubInView ? 'visible' : 'hidden'}
        variants={variants}
        className={colorFlashClass}
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginBottom: '20px' }}>
          <a
            href="https://github.com/zdrawia"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faGithub} size="2x" style={{ marginRight: '10px' }} />
            <h2 className={applyGlitch ? 'glitch' : ''} style={{ fontSize: '36px', fontWeight: 'bold', textShadow: '2px 2px 2px black' }}>
              GitHub
            </h2>
          </a>
          <div style={{ width: '20px' }}></div>
          <a
            href="https://tea4u.itch.io/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faItchIo} size="2x" style={{ marginRight: '10px' }} />
            <h2 className={applyGlitch ? 'glitch' : ''} style={{ fontSize: '36px', fontWeight: 'bold', textShadow: '2px 2px 2px black' }}>
              itch.io
            </h2>
          </a>
        </div>
        <footer
          style={{
            position: 'fixed',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: 'white',
            fontSize: '14px',
            opacity: '0.2',
          }}
        >
          © {new Date().getFullYear()} Innocent Moon. All Rights Reserved.
        </footer>
      </motion.div>
    </div>
  );
};

export default Home;
