import React from 'react';
import '../css/AyanamiResearch.css';

const AyanamiResearch = () => {
    return (
      <div className="ayanami-container">
        <div className="background"></div>
        <div className="smoke-overlay">
          <div className="smoke-layer"></div>
          <div className="smoke-layer"></div>
          <div className="smoke-layer"></div>
          <div className="smoke-layer"></div>
          <div className="smoke-layer"></div>
        </div>
        <div className="content">
          <h1 className="ayanamiheader">Ayanami Research</h1>
          <p className="ayanamiquote">The fate of destruction is also the joy of rebirth</p>
        </div>
        <footer style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', color: 'black', fontSize: '14px', opacity: '0.8' }}>
            © {new Date().getFullYear()} Innocent Moon. All Rights Reserved.
        </footer>
      </div>
    );
  };
  
  export default AyanamiResearch;
