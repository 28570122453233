import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from './components/Navigation';
import AnimatedRoutes from './components/AnimatedRoutes';
import './css/App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation /> 
        <AnimatedRoutes />
      </div>
    </Router>
  );
}

export default App;
