import React from 'react';
import '../css/GlitchEffect.css';

const GlitchEffect = ({ text, className, glitchSpeed = '2s', glitchIntensity = '2px' }) => (
    <span className={className} data-text={text} style={{ '--glitch-speed': glitchSpeed, '--glitch-intensity': glitchIntensity }}>
        {text}
    </span>
);

export default GlitchEffect;
