import React from 'react';
import { motion } from 'framer-motion';
import '../css/Loading.css';

const Loading = () => {
  return (
    <motion.div
      className="loading-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="spinner"></div>
    </motion.div>
  );
};

export default Loading;
